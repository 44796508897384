import React from "react";
import Home from "../../Assests/images/home.png";
import Rising from "../../Assests/images/rising.png";
import Settings from "../../Assests/images/settings.png";
import Profile from "../../Assests/images/profile.png";
import sidebarStyles from "./SideBar.module.css";

const SideBar = () => {
  return (
    <>
      <div className={sidebarStyles.left_content}>
        <div className={sidebarStyles.img_container}>
          <a href="/ride">
            <img
              src={Home}
              alt="profile"
              className={sidebarStyles.profile_img}
            />
          </a>
        </div>
        <div className={sidebarStyles.img_container}>
          <a href="/update_profile">
            <img
              src={Rising}
              alt="profile"
              className={sidebarStyles.profile_img}
            />
          </a>
        </div>
        <div className={sidebarStyles.img_container}>
          <a href="/settings">
            <img
              src={Settings}
              alt="profile"
              className={sidebarStyles.profile_img}
            />
          </a>
        </div>
        <div className={sidebarStyles.img_container}>
          <a href="/update_profile">
            <img
              src={Profile}
              alt="profile"
              className={sidebarStyles.profile_img}
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default SideBar;
