import React from "react";
import Header from "../../Components/Header/Header";
import SideBar from "../../Components/SideBar/SideBar";
import UpdateProfileStyles from "./UpdateProfile.module.css";
import UpdateProfileForm from "../../Components/Update Profile/UpdateProfileForm";

const UpdateProfile = () => {
  return (
    <>
      <section>
        <Header />
      </section>
      <section className={UpdateProfileStyles.content}>
        <div id={UpdateProfileStyles.left}>
          <SideBar />
        </div>
        <div id={UpdateProfileStyles.right}>
          <h1 className="text-center">Update Profile</h1>

          <UpdateProfileForm />
        </div>
      </section>
    </>
  );
};

export default UpdateProfile;
