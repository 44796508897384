import React from "react";
import settingsFormStyles from "./SettingsForm.module.css";
import { AiOutlineSend } from "react-icons/ai";

const settingsForm = () => {
  return (
    <>
      <form>
        <div className="container" id={settingsFormStyles.main}>
          <div className="mb-3">
            <label className="form-label">Current Password</label>
            <input
              className="form-control"
              type="password"
              placeholder="** ** ** **"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">New Password</label>
            <input
              className="form-control"
              type="password"
              placeholder="** ** ** **"
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Confirm Password</label>
            <input
              className="form-control form-control-sm"
              type="password"
              placeholder="** ** ** **"
            />
          </div>

          <div className={settingsFormStyles.signup}>
            <button>
              Confirm{" "}
              <span>
                <AiOutlineSend color="#8C5400" size={16} />
              </span>
            </button>
          </div>
        </div>
      </form>
      <section></section>
    </>
  );
};

export default settingsForm;
