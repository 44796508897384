import { BrowserRouter, Routes, Route } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Register";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Ride from "./Pages/Ride/Ride";
import UpdateProfile from "./Pages/Update Profile/UpdateProfile";
import Details from "./Pages/Details/Details";
import Settings from "./Pages/Settings/Settings";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/ride" element={<Ride />} />
        <Route path="/details" element={<Details />} />
        <Route path="/update_profile" element={<UpdateProfile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="*" element={<h1>404</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
