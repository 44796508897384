import React from "react";
import LoginStyles from "./Login.module.css";
import { AiOutlineSend } from "react-icons/ai";

const Login = () => {
  return (
    <div className={LoginStyles.loginMain}>
      <main className={LoginStyles.col}>
        <section className={LoginStyles.side_left}>
          <h1 className={LoginStyles.side_left_content}>{""}</h1>
        </section>
        <section className={LoginStyles.side_right}>
          <h1>Login</h1>
          <div>
            <form>
              <div className="container" id={LoginStyles.main}>
                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <input
                    className="form-control"
                    type="email"
                    placeholder="example@mail.com"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Password</label>
                  <input
                    className="form-control form-control-sm"
                    type="password"
                    placeholder="********"
                  />
                </div>

                <div className={LoginStyles.signup}>
                  <button>
                    SIGN IN{" "}
                    <span>
                      <AiOutlineSend color="#8C5400" size={18} />
                    </span>
                  </button>
                </div>
              </div>
            </form>
            <section className={LoginStyles.base}>
              <div>
                <a href="/#">Forgot password ?</a>
              </div>
              <div>
                <a href="/register">Register</a>
              </div>
            </section>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Login;
