import React from "react";
import updateProfoleStyles from "./UpdateProfileForm.module.css";
import { AiOutlineSend } from "react-icons/ai";

const UpdateProfileForm = () => {
  return (
    <div>
      <>
        <form>
          <div className="container" id={updateProfoleStyles.main}>
            <div className="mb-3">
              <label className="form-label">Firstname</label>
              <input
                className="form-control"
                type="text"
                placeholder="Kelvin"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Lastname</label>
              <input
                className="form-control"
                type="text"
                placeholder="Ogbonna"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Email</label>
              <input
                className="form-control"
                type="email"
                placeholder="example@mail.com"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Phone</label>
              <input
                className="form-control form-control-sm"
                type="tel"
                placeholder="+233 000 000 000"
              />
            </div>
            <div>
              <label className="form-label">Address</label>
              <input
                className="form-control form-control-lg"
                type="text"
                placeholder="Nima, Accra"
              />
            </div>
            <div className={updateProfoleStyles.signup}>
              <button>
                Update{" "}
                <span>
                  <AiOutlineSend color="#8C5400" size={16} />
                </span>
              </button>
            </div>
          </div>
        </form>
        <section></section>
      </>
    </div>
  );
};

export default UpdateProfileForm;
