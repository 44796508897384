import React from "react";
import LoginStyles from "./Register.module.css";
import RegisterForm from "../../Components/Register/RegisterForm";

const Register = () => {
  return (
    <div className={LoginStyles.loginMain}>
      <main className={LoginStyles.col}>
        <section className={LoginStyles.side_left}>
          <h1 className={LoginStyles.side_left_content}>{""}</h1>
        </section>
        <section className={LoginStyles.side_right}>
          <h1>Sign Up</h1>
          <div>
            <RegisterForm />
          </div>
        </section>
      </main>
    </div>
  );
};

export default Register;
