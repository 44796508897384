import React from "react";
import Header from "../../Components/Header/Header";
import DetailStyles from "./Details.module.css";
import Package from "../../Assests/images/package.png";
import Phone from "../../Assests/images/jeremy-bezanger-aHK4vGnd2Lg-unsplash.jpg";
import Building from "../../Assests/images/buildings.png";
import Location from "../../Assests/images/location.png";
import Call from "../../Assests/images/phone.png";
import Email from "../../Assests/images/email.png";

const Details = () => {
  return (
    <>
      <section>
        <Header />
      </section>
      <section className={DetailStyles.content}>
        <div id={DetailStyles.left}>
          <div className={DetailStyles.left_top}>
            <img src={Package} alt="side_image" id={DetailStyles.side_image} />
          </div>
        </div>

        <div id={DetailStyles.right}>
          <div className={DetailStyles.main_contents}>
            <div className={DetailStyles.items}>
              <section className={DetailStyles.image_section}>
                <img src={Phone} className="card-img-top" alt="..." />
              </section>
              <hr />
              <section className={DetailStyles.content_section}>
                <ul>
                  <li>
                    <span className="mx-3">
                      <img
                        src={Building}
                        alt="side_image"
                        className={DetailStyles.list_images}
                      />
                    </span>
                    Larry Phones
                  </li>
                  <li>
                    <span className="mx-3">
                      <img
                        src={Package}
                        alt="side_image"
                        className={DetailStyles.list_images}
                      />
                    </span>
                    iPhones 13
                  </li>
                  <li>
                    <span className="mx-3">
                      <img
                        src={Location}
                        alt="side_image"
                        className={DetailStyles.list_images}
                      />
                    </span>
                    Circle
                  </li>
                  <li>
                    <span className="mx-3">
                      <img
                        src={Call}
                        alt="Call"
                        className={DetailStyles.list_images}
                      />
                    </span>
                    05015002465
                  </li>
                  <li>
                    <span className="mx-3">
                      <img
                        src={Email}
                        alt="side_image"
                        className={DetailStyles.list_images}
                      />
                    </span>
                    example@gmail.com
                  </li>
                </ul>
              </section>
            </div>
            <div className={DetailStyles.items}>
              <section className={DetailStyles.map_image}>
                <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe
                      width={600}
                      height={500}
                      id="gmap_canvas"
                      src="https://maps.google.com/maps?q=achimota&t=&z=13&ie=UTF8&iwloc=&output=embed"
                      frameBorder={0}
                      scrolling="no"
                      marginHeight={0}
                      marginWidth={0}
                      title="map"
                    />
                    <a href="https://fmovies-online.net" alt="..." />
                    <br />
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          ".mapouter{position:relative;text-align:right;height:500px;width:600px;}",
                      }}
                    />
                    <a href="https://www.embedgooglemap.net">
                      custom google maps embed
                    </a>
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          ".gmap_canvas {overflow:hidden;background:none!important;height:500px;width:600px;}",
                      }}
                    />
                  </div>
                </div>
              </section>
              <hr />
              <section className={DetailStyles.map_content}>
                <ul>
                  <li>
                    <span className="mx-3">
                      <img
                        src={Location}
                        alt="side_image"
                        className={DetailStyles.list_images}
                      />
                    </span>
                    Circle
                  </li>
                  <li>
                    <span className="mx-3">
                      <img
                        src={Call}
                        alt="Call"
                        className={DetailStyles.list_images}
                      />
                    </span>
                    05015002465
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Details;
