import React from "react";
import HeaderStyles from "./Header.module.css";
import Logo from "../../Assests/images/rider.png";
import Logout from "../../Assests/images/log-out.png";
import Profile from "../../Assests/images/pexels-spencer-selover-428361.jpg";

const Header = () => {
  return (
    <section className={HeaderStyles.main}>
      <div>
        <a href="/">
          <img src={Logo} alt="logo" id={HeaderStyles.logoImage} />
          <span>Rider</span>
        </a>
      </div>
      <div className={HeaderStyles.profile_section}>
        <div>
          <img src={Profile} alt="profile" id={HeaderStyles.profile_img} />
        </div>
        <div>
          <span id={HeaderStyles.profile_name}>Kwame</span>
        </div>
        <div>
          <img src={Logout} alt="logo" id={HeaderStyles.profile_arrow} />
        </div>
      </div>
    </section>
  );
};

export default Header;
