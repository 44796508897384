import React from "react";
import Header from "../../Components/Header/Header";
import SideBar from "../../Components/SideBar/SideBar";
// import RideData from "../../Components/RidaData/RideData";
import RideStyles from "./Ride.module.css";

const Ride = () => {
  return (
    <>
      <section>
        <Header />
      </section>
      <section className={RideStyles.content}>
        <div id={RideStyles.left}>
          <SideBar />
        </div>
        <div id={RideStyles.right}>
          {/* <RideData /> */}
          <>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First</th>
                  <th scope="col">Last</th>
                  <th scope="col">Handle</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td colSpan={2}>Larry the Bird</td>
                  <td>@twitter</td>
                </tr>
              </tbody>
            </table>
          </>
        </div>
      </section>
    </>
  );
};

export default Ride;
