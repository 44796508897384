import React from "react";
import Header from "../../Components/Header/Header";
import SideBar from "../../Components/SideBar/SideBar";
import settingsStyles from "./Settings.module.css";
import Datasettings from "../../Components/Settings/SettingsForm";

const Settings = () => {
  return (
    <>
      <section>
        <Header />
      </section>
      <section className={settingsStyles.content}>
        <div id={settingsStyles.left}>
          <SideBar />
        </div>
        <div id={settingsStyles.right}>
          <Datasettings />
        </div>
      </section>
    </>
  );
};

export default Settings;
