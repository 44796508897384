import React from "react";
import dashboardStyles from "./Dashboard.module.css";
import { SiFacebook } from "react-icons/si";
import Password from "../../Assests/images/password.png";
import Riders from "../../Assests/images/rider.png";
import { AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai";

const Dashboard = () => {
  return (
    <section className={dashboardStyles.main}>
      <header className={dashboardStyles.header}>
        <div className={dashboardStyles.logo}>
          <img src={Riders} alt="logo" />{" "}
          <span className={dashboardStyles.logo_text}>Riders</span>
        </div>
        <div>
          <a href="/login" className={dashboardStyles.signup_button}>
            sign in{" "}
          </a>
        </div>
      </header>
      <main className={dashboardStyles.content}>
        <h1>We make Deliveries Fast,</h1>
        <h5>Easy & Affordable</h5>

        <a className="btn btn-light p-3" href="/register">
          <img
            src={Password}
            alt="password"
            className={dashboardStyles.img_container}
          />
          <span className="mx-2">Register</span>
        </a>
      </main>
      <footer className={dashboardStyles.footer}>
        <a href="/register">
          <AiOutlineTwitter size={24} />
        </a>
        <a href="/register">
          <SiFacebook size={24} />
        </a>
        <a href="/register">
          <AiOutlineInstagram size={24} />
        </a>
      </footer>
    </section>
  );
};

export default Dashboard;
